import { Component, Inject, OnInit, PLATFORM_ID, Signal, WritableSignal, computed, inject, signal, viewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CarouselComponent } from '../shared/carousel/carousel.component';
import { ProductsService } from './products.service';
import { NotificationService } from '../shared/notification/notification.service';
import { SignalsStoreService } from '../shared/signals-store.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StockService } from '../stock/stock.service';
import { LocalStorageService } from '../shared/local-storage.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { NotificationsComponent } from '../shared/notification/notification.component';
import { finalize, tap } from 'rxjs';
import { ApiResponse } from '../shared/common/types';
import { KlaviyoService } from '../shared/klaviyo/klaviyo.service';
import { KLAVIYOEVENTS } from '../shared/klaviyo/events';
import { capitalizeWords, unableOperationMessage } from '../shared/common/utils';
import { ModalContentService } from '../shared/modal-content/modal-content.service';
import { FIREBASE_COLLECTIONS } from '../shared/constants/databases';
import { ModalContentTypes } from '../shared/constants/modal-content-types';
import { environment } from '../../environments/environment';
import { FirebaseCrudService } from '../shared/firebase-crud.service';
import { ProductCardV2Component } from '../shared/product-card-v2/product-card-v2.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product',
  imports: [
    RouterModule,
    ProductCardV2Component,
    CarouselComponent,
    CommonModule,
    MatProgressBarModule,
    NotificationsComponent
  ],
  providers: [
    NotificationService,
    ProductsService,
  ],
  templateUrl: './product.component.html',
  styleUrl: './product.component.scss'
})

export class ProductComponent implements OnInit {
  #meta = inject(Meta);
  #title = inject(Title);
  private productCard = viewChild.required<ProductCardV2Component>('productCard');
  private route = inject(ActivatedRoute);
  private productsService = inject(ProductsService);
  signalsStoreService = inject(SignalsStoreService);
  private router = inject(Router);
  private modalContentService = inject(ModalContentService);
  localStorageService = inject(LocalStorageService)
  stockService = inject(StockService)
  private klaviyoService = inject(KlaviyoService);
  private firebaseCrudService = inject(FirebaseCrudService);

  seoURL!: string;
  isContentLoaded: WritableSignal<boolean> = signal(false);

  showDiscountTag = signal(false);
  product: Signal<any> = computed(() => this.productsService.productSignal() || null)
  productCardSummary: Signal<any> = computed(() => this.setUpProductCardSummary());
  carouselItems: Signal<any> = computed(() => this.setUpCarouselItems());
  productImages: Signal<any[]> = computed(() => this.setUpProductImages());
  updateIsDislike = signal(false);
  isFavorite = computed(() => this.checkFavProduct());
  isDislike = computed(() => this.#checkDislikeProduct());
  selectedAttribute: WritableSignal<any> = signal('');

  productId: WritableSignal<number> = signal(0);
  stockSignal = computed(() => this.stockService.mapStockSignal());
  obsStockSignal = toObservable(this.stockSignal);
  variant: WritableSignal<any> = signal(null);

  likeTotals = computed(() => this.setUpFavTotals());
  showMultipleImages = signal(environment.config.flows.productDetail?.showMultipleImages ?? true);
  private stockMapKey: any = null;

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.handleRouteChanges();
    this.isContentLoaded = this.signalsStoreService.isContentLoaded;
  }

  #checkDislikeProduct() {
    this.updateIsDislike();
    let isDislike = false;
    isDislike = !!this.product().disliked;

    if (this.variant())
      isDislike = this.variant().disliked;

    return isDislike;
  }

  private setUpSEOTags(product: string) {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = `shop/${product}`
    const title = `${capitalizeWords(product.replace(/\//g, ' | '))} | ${baseTitle}`;

    // Page title
    this.#title.setTitle(title);

    // Open Graph for social networks:
    this.#meta.addTag({ property: 'og:title', content: title });
    this.#meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.#meta.addTag({ property: 'og:type', content: type });
  }

  private handleRouteChanges(): any {
    this.route.params.subscribe((params) => {
      this.seoURL = `${params['category']}/${params['subcategory']}/${params['product']}`;
      this.setUpSEOTags(this.seoURL);
      this.productsService.getProductByUrl(this.seoURL).pipe(
        tap(({ data: product }: ApiResponse<any>) => {
          if (!this.signalsStoreService.hasSession()) return;
          if (product) {
            this.klaviyoService.trackEvent(KLAVIYOEVENTS.ProductViewed, {
              bundle: product.bundle?.items || [],
              category: product.category,
              id: product.id,
              name: product.name,
              price: product.price,
              productUrl: product.productUrl,
              size: product.size || '',
              subcategory: product.subCategory,
              originalPrice: product.originalPrice,
              producer: product.producer,
              specialCategory: product.specialCategory,
              tags: product.tags
            });
          }
        })
      ).subscribe()
    });
  }

  setUpProductCardSummary() {
    const productDetails = this.productsService.productSignal();
    localStorage.setItem('selectedProductId', productDetails.id);
    return {
      productDetails,
      settings: {
        isSummary: true
      }
    };
  }

  private setUpProductImages() {
    const product = this.productsService.productSignal();
    if (!product) return [];
    if (this.variant()?.image)
      return !!(this.variant()?.image || this.variant()?.additionalImages?.length) ? [this.variant()?.image, ...(this.variant()?.additionalImages || [])] : ['assets/images/product-placeholder.webp'];
    return !!(product?.img || product?.additionalImages?.length) ? [product?.img, ...product?.additionalImages] : ['assets/images/product-placeholder.webp'];
  }

  private setUpCarouselItems() {
    const product = this.productsService.productSignal();
    if (!product) return [];
    return product?.suggestedProducts?.map((suggestedProduct: any) => {
      suggestedProduct.image = 'assets/images/product-placeholder.webp';

      return ({
        content: {
          productDetails: { ...suggestedProduct, isSubscription: false },
          settings: {
            showSizes: false,
            showFlavors: false,
            showContentInfo: false,
            hideWhenWasOOS: true
          }
        }
      })
    });
  }

  gotoCategory() {
    this.router.navigate(['/shop/' + (this.product()?.category?.name || '').toLowerCase().replace(/\s/g, '-')]);
  }

  onSelectedVariant(e: any) {
    this.variant.set(e);
  }

  handleFavoriteChange() {
    // If there is not stock yet, we shoul prevent for this acction:
    if (!this.stockService.mapStockSignal()?.size) return;
    // TODO VALIDATION, IF EP STOCK FAILS, MUST SHOW A ERROR MESSAGE
    const bundle = this.product().bundle || null;
    // If the product already has a variantId, must use it (it happens in buy-again or favorites)
    let variant = this.product().packages?.length ? this.getVariantFromPackage() : this.getVariant();
    let variantId: number;
    if (variant == null) {
      variant = this.variant()
      variantId = variant.variantId
    } else {
      variantId = variant.id
    }

    if (!variant)
      return unableOperationMessage(this.modalContentService);

    if (!bundle && !variant?.stock)
      return unableOperationMessage(this.modalContentService);

    if (!this.isFavorite())
      this.productsService
        .setFavorite(variantId)
        .pipe(
          tap(() => {
            this.variant.update((current: any) => {
              return { ...current, disliked: false, fav: true }
            });
            this.updateProductSignalFavValue(true, false);
            this.#updateDislikedItemsFirebase(variantId, false, true);
          })
        ).subscribe();
    else
      this.productsService
        .removeFavorite(variantId)
        .pipe(
          finalize(() => {
            this.variant.update((current: any) => {
              return { ...current, fav: false }
            });
            this.updateProductSignalFavValue(false, this.isDislike());
            this.#updateDislikedItemsFirebase(variantId, false, false);
          })
        ).subscribe();
  }

  prevDislikeProduct() {

    if (this.isDislike())
      return this.#handleDislikeChange();

    if (this.signalsStoreService.userPreferences().dontShowAgainPrevDislike?.value)
      return this.#handleDislikeChange();

    this.modalContentService
      .openModal(ModalContentTypes.DISLIKE_PRODUCT_CONFIRMATION, {
        title: 'Are you sure?',
        textContent: `You are about to dislike a product. We will do our best to never send you a product that you don't like. You can always adjust these settings later in your profile.`
      })
      .closed
      .subscribe((res: { isConfirm: boolean }) => {
        if (!res.isConfirm) return;
        this.#handleDislikeChange();
      });
  }

  #handleDislikeChange() {
    // If there is not stock yet, we shoul prevent for this acction:
    if (!this.stockService.mapStockSignal()?.size) return;
    // TODO VALIDATION, IF EP STOCK FAILS, MUST SHOW A ERROR MESSAGE
    const bundle = this.product().bundle || null;
    // If the product already has a variantId, must use it (it happens in buy-again or favorites)
    let variant = this.product().packages?.length ? this.getVariantFromPackage() : this.getVariant();
    let variantId: number;
    if (variant == null) {
      variant = this.variant()
      variantId = variant.variantId
    } else {
      variantId = variant.id
    }

    if (!variant)
      return unableOperationMessage(this.modalContentService);

    if (!bundle && !variant?.stock)
      return unableOperationMessage(this.modalContentService);

    if (!this.isDislike())
      this.productsService
        .setDislike(variantId)
        .pipe(
          tap(() => {
            this.variant.update((current: any) => {
              return { ...current, disliked: true, fav: false }
            });
            this.updateProductSignalFavValue(false, true);
            this.#updateDislikedItemsFirebase(variantId, true, false);
          })
        ).subscribe();
    else
      this.productsService
        .removeDislike(variantId)
        .pipe(
          tap(() => {
            this.variant.update((current: any) => {
              return { ...current, disliked: false }
            });
            this.updateProductSignalFavValue(this.isFavorite(), false);
            this.#updateDislikedItemsFirebase(variantId, false, false);
          })
        ).subscribe();
  }

  private updateProductSignalFavValue(fav: boolean, disliked: boolean) {
    this.productsService.productSignal.update((current: any) => {
      const variantIdToUpdate = this.variant().variantId;
      let mainAttributeIndex = -1;
      if (variantIdToUpdate)
        mainAttributeIndex = current.attributes.findIndex((item: any) => item.values.some((value: any) => value.variantId === variantIdToUpdate));

      if (mainAttributeIndex > -1) {
        const mainAttribute = current.attributes[mainAttributeIndex]
        if (mainAttribute) {
          mainAttribute.values = mainAttribute.values.map((value: any) => {
            if (value.variantId == variantIdToUpdate) {
              return { ...value, fav, disliked }
            }
            return value;
          })
        }
        current.attributes[mainAttributeIndex] = mainAttribute
      }

      return current;

    })
  }

  getVariant() {
    let stock;

    stock = this.stockService.mapStockSignal();

    if (!stock?.size) return null;

    this.stockMapKey = this.getKeyForStockMap();
    const variant = stock.get(this.stockMapKey);
    if (variant != null) {
      variant.id = variant.variantId;
    }
    return variant ?? null;
  }

  getVariantFromPackage() {
    let stock = this.stockService.mapStockSignal();

    if (!stock) return null;

    this.stockMapKey = this.getKeyForStockMap();
    const variant = stock.get(this.stockMapKey);
    variant.id = variant.variantId;
    variant.attribute = this.selectedAttribute() || null
    return variant ?? null;
  }

  private getKeyForStockMap() {
    const productId = this.product().id;
    let key: any = productId;
    if (this.selectedAttribute().id && this.selectedAttribute().value?.id)
      key = `${productId}_${this.selectedAttribute().id}_${this.selectedAttribute().value.id}`;

    return key;
  }

  private checkFavProduct() {
    let isFavorite = false;
    isFavorite = !!this.product().fav

    if (this.variant()) {
      isFavorite = this.variant().fav
    }

    return isFavorite;
  }

  private setUpFavTotals() {
    const product = this.product();
    const variant = this.variant();
    if (variant)
      return variant.likeTotals || 0;

    return product.likeTotals || 0;
  }

  whatsInside(event: Event) {
    this.productCard()
      .openModalWhatsInside(event);
  }

  onChangeShowDisccountTag(event: boolean) {
    this.showDiscountTag.set(event);
  }

  //#region Update like/dislike property in firebase

  #updateDislikedItemsFirebase(productId: number, disliked: boolean, fav: boolean) {

    const session = this.signalsStoreService.sessionSignal();
    if (!session) return;

    const userId = session.accountInfo.id.toString();

    this.firebaseCrudService
      .getByIdNoValueChange(FIREBASE_COLLECTIONS.ORDERS, userId)
      .pipe(
        tap(async res => {

          this.#updateDislikeFirebase(res?.orderProducts?.products?.subscription ?? [], productId, disliked, fav);
          this.#updateDislikeFirebase(res?.orderProducts?.products?.common ?? [], productId, disliked, fav);

          await this.firebaseCrudService
            .update(FIREBASE_COLLECTIONS.ORDERS, userId, res);
        })
      )
      .subscribe();
  }

  #updateDislikeFirebase(products: any[], productId: number, disliked: boolean, fav: boolean) {
    for (const product of products) {
      if (product.bundle.items.length) {
        const productIndex = product.bundle.items.findIndex((x: any) => x.id === productId);
        if (productIndex !== -1) {
          product.bundle.items[productIndex].disliked = disliked;
          product.bundle.items[productIndex].fav = fav;
        }
      } else if (product.id === productId) {
        product.disliked = disliked;
        product.fav = fav;
      }
    }
  }

  //#endregion
}
