import { Component, HostListener, Inject, OnInit, PLATFORM_ID, Signal, ViewChild, WritableSignal, computed, inject, signal } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { SignalsStoreService } from '../signals-store.service';
import { AuthService } from '../../authentication/auth.service';
import { LocalStorageService } from '../local-storage.service';
import { DeliveriesService } from '../../settings/account/deliveries/deliveries.service';
import { ModalContentService } from '../modal-content/modal-content.service';
import { ModalContentTypes } from '../constants/modal-content-types';
import { FormsModule } from '@angular/forms';
import { ModalContentData } from '../modal-content/modal-content';
import { isPlatformBrowser, CommonModule, Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { combineLatest, combineLatestAll, delay, filter, finalize, map, tap } from 'rxjs';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { HeaderService } from './header.service';
import { MenuService } from '../menu.service';
import { ProductsService } from '../../product/products.service';
import { FIREBASE_COLLECTIONS, LOCALSTORAGE_KEYS } from '../constants/databases';
import { Session } from '../types/session.type';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../order.service';
import { SubscriptionsService } from '../../settings/account/subscriptions/subscriptions.service';
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { ResolutionService } from '../resolution.service';
import { NAVIGATION_ITEMS, RIGHT_ELEMENTS, SETTINGS_PAGES } from './header.lists';
import { StockService } from '../../stock/stock.service';
import { AccountService } from '../account.service';
import { eCommercePermissions } from '../types/account.types';
import { formatDateToReadableString } from '../utils/formatting';
import { KlaviyoService } from '../klaviyo/klaviyo.service';
import { KLAVIYOEVENTS } from '../klaviyo/events';
import { formatStringForURL, isAddressUpdateRequired } from '../common/utils';
import { SidebarService } from '../sidebar/sidebar.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { SidebarCheckList } from '../sidebar/sidebar.types';
import { PaymentMethodSignupService } from '../../authentication/signup/payment-method-signup/payment-method-signup.service';
import { FirebaseCrudService } from '../firebase-crud.service';
import { Item, UserPreferences } from '../types/common.types';
import { DateTime } from 'luxon';
import { RightElement } from './header.types';
import { TagsFilterComponent } from './tags-filter/tags-filter.component';
import { Product } from '../../product/product.types';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  imports: [
    RouterLink,
    FormsModule,
    SearchBarComponent,
    CommonModule,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgbModule,
    TagsFilterComponent
  ],
  providers: [SubscriptionsService],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  signalsStoreService = inject(SignalsStoreService);
  modalContentService = inject(ModalContentService);
  sidebarService = inject(SidebarService);
  authService = inject(AuthService);
  HAS_MEMBERSHIP_FLOW: boolean = !!environment.config.flows.membership;

  private localStorageService = inject(LocalStorageService);
  private deliveriesService = inject(DeliveriesService);
  private router = inject(Router);
  private headerService = inject(HeaderService);
  private menuService = inject(MenuService);
  private productService = inject(ProductsService);
  private activeModal = inject(NgbModal);
  private orderService = inject(OrderService);
  private location = inject(Location);
  private subscriptionsService = inject(SubscriptionsService);
  private resolutionService = inject(ResolutionService);
  private stockService = inject(StockService);
  private productsService = inject(ProductsService);
  private accountService = inject(AccountService);
  private klaviyoService = inject(KlaviyoService);
  private paytmentMethodSignupService = inject(PaymentMethodSignupService);
  private firebaseService = inject(FirebaseCrudService);
  private meta = inject(Meta);
  private title = inject(Title);

  orderProductsQuantity = computed(() => this.getOrderProductsQuantity());
  isSessionLoaded = computed(() => this.signalsStoreService.isSessionLoaded());
  shouldShowCategoriesBar = computed(() => this.signalsStoreService.shouldShowCategoriesBar());
  isCustomBoxSignupFlow = computed(() => this.signalsStoreService.isCustomBoxSignupFlow());

  routeSignal = signal(this.router.url);

  isShowingSearchBar = computed(() => !!(this.headerService.displaySearchBar() || this.signalsStoreService.filterByProducer()));
  isSidebarOpen = this.signalsStoreService.isSidebarOpen;

  isShowingSearchMobile = computed(() => (!!(this.headerService.displaySearchBar() || this.signalsStoreService.filterByProducer()) && this.isMobile()));

  userName: WritableSignal<string> = signal('');

  menuItems = this.menuService.menu;

  isMobile = computed(() => this.resolutionService.isMobile());

  landingPages = signal(NAVIGATION_ITEMS);
  settingsPages = computed(() => this.setUpSettingsPermissions());
  rightElements = computed(() => this.setUpRightElementPermissions());

  isShowingMobileFooter = this.signalsStoreService.isShowingMobileFooter;

  logoVersionNumber = computed(() => this.signalsStoreService.logoVersionNumber());

  isContentLoading = computed(() => this.stockService.isLoading() || this.productsService.isLoading());
  isContentLoaded = computed(() => this.stockService.isLoaded() || this.productsService.isLoaded());

  $isLoadedFirebaseData = toObservable(this.signalsStoreService.isLoadedFirebaseData);
  $isLoadedDeliveryInfo = toObservable(this.signalsStoreService.isLoadedDeliveryInfo);

  session = computed(() => this.signalsStoreService.sessionSignal());

  hasSession = computed(() => !!this.signalsStoreService.hasSession());

  @ViewChild(MatSelect) matSelect!: MatSelect;

  subcategorySelected!: number;

  tagsSignal: Signal<Item[]> = computed(() => this.productsService.tagsSignal());

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.#categoryInViewPort();

    if (isPlatformBrowser(this.platformId))
      this.#openModalDislikeFav();
  }

  #categoryInViewPort() {
    toObservable(
      this.signalsStoreService
        .categoryInViewPort
    )
      .subscribe(obs => {
        if (!obs) return;

        const { categoryId, subCategoryId } = obs;

        const index = this.sidebarService
          .sidebar()
          .findIndex(x => x.id === categoryId);

        if (index !== -1)
          this.subcategorySelected = categoryId;
        else
          this.subcategorySelected = subCategoryId;
      });
  }

  getOrderProductsQuantity() {
    const odooOrder = this.orderService.odooOrder();
    const firebaseOrder = this.signalsStoreService.firebaseOrder();
    const allProducts = [
      ...(odooOrder?.products?.common ?? []),
      ...(odooOrder?.products?.subscription ?? []),
      ...(firebaseOrder?.products?.common ?? []),
      ...(firebaseOrder?.products?.subscription ?? []),
    ];

    if (odooOrder?.isSkipped) return 0;

    return new Set(allProducts.map((product: any) => {
      const variantId = product.variant.id;
      const packageId = product.package?.id || null;
      const id = `${variantId}${packageId ? `_${packageId}` : ''}`;
      return id
    })).size;
  }

  logOut() {
    this.authService.logout();
  }

  ngOnInit(): void {

    combineLatest(
      [this.$isLoadedDeliveryInfo, this.$isLoadedFirebaseData],
      (isLoadedDeliveryInfo, isLoadedFirebaseData) => {
        if (!isLoadedDeliveryInfo || !isLoadedFirebaseData) return;
        this.validateIfShouldUpdateOrder();
      })
      .subscribe();

    if (this.signalsStoreService.filterByProducer()) {
      this.headerService.displaySearchBar.set(true);
    }
    this.subscribeToRouteChanges();
    this.setUpMenu();
    this.signalsStoreService.getAndSyncCart();
    this.setUpUserSession();
    this.getSubscriptions();
  }

  private setUpMenu() {
    if (!isPlatformBrowser(this.platformId)) return;
    this.menuService.fetchMenuCategories().subscribe({
      next: () => this.setUpSelectedCategory(this.location.path()),
    });
  }

  private setUpUserSession() {
    const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    const permissions: eCommercePermissions | null = this.localStorageService.get(LOCALSTORAGE_KEYS.PERMISSIONS);
    if (session?.accountInfo?.id) {
      this.#getUserPreferences(session?.accountInfo?.id);
      this.accountService.getMembershipPermissions().subscribe();
      this.headerService.getFrequencies();
      this.userName.set(session.accountInfo.firstName.toUpperCase());
      if (
        !(!!(permissions?.settings.paymentMethod.allowed) && !(session?.settings?.hasPaymentMethod)) &&
        !(!!(session?.settings?.requireAddress) && !(session?.address?.street))
      ) {
        this.getOrder();
      }

      this.deliveriesService
        .getDeliveryZoneInfo()
        .pipe(
          tap(() => this.validateNewUserOrFirstLogin()),
          tap(() => this.signalsStoreService.isLoadedDeliveryInfo.set(true))
        )
        .subscribe();
    }
  }

  toggleSearchBar() {
    // this.isShowingSearchBar.update((current: boolean) => !current);
    this.headerService.updateSearchBarDisplay();
  }

  toggleSearchMobile() {
    this.headerService.updateSearchBarDisplay();
    if (this.signalsStoreService.isFiltering()) this.headerService.clearFilters(true);
  }

  toggleCategorySideMenu() {
    this.signalsStoreService.isSidebarOpen.update(
      (currentValue) => !currentValue
    );
  }

  private subscribeToRouteChanges() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.routeSignal.set(this.router.url);
        this.setUpSelectedCategory(event.url);
      });
  }

  private getOrder() {
    if (!isPlatformBrowser(this.platformId)) return;
    if (!this.hasSession()) return;
    const currentUrl = this.router.url;
    if (((!currentUrl.includes('/order') || currentUrl.includes('/order-and-billing')) && !currentUrl.includes('/cart')) || this.signalsStoreService.isLimitedUser())
      this.orderService.getOrder(this.signalsStoreService.marketStatus);
  }

  openModalWelcomeMessage() {
    const sessionData = (): any => {
      const hasSession = this.hasSession();
      const userData: Session | null = hasSession ? this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) : null;
      const deliveryInfo = userData?.deliveryInfo;
      return {
        title: `Welcome to ${environment.application.name}`,
        hasSession,
        fullScreenOnMobile: true,
        welcomeMessage: {
          ...deliveryInfo,
        },
      } as ModalContentData;
    };
    const data: ModalContentData = sessionData();
    this.modalContentService.openModal(ModalContentTypes.WELCOME_MESSAGE, data);
  }

  triggerCategoryInfoFetch(
    category: { id: number; name: string },
    navigate: boolean = true
  ) {
    if (this.isContentLoading()) return;
    const formattedCategoryName = formatStringForURL(category.name);
    const parts = this.location.path().split('/');
    const currentCategoryName = parts[parts.length - 1].toLowerCase();
    if (formattedCategoryName === currentCategoryName && navigate && parts.length === 3) return;
    navigate ? this.router.navigate([`/shop/${formattedCategoryName}`]) : null;
    this.signalsStoreService.selectedCategory.set(category);
    this.setUpSEOTags(category.name, formattedCategoryName);
    this.localStorageService.set(LOCALSTORAGE_KEYS.SELECTED_CATEGORY, category);
  }

  private validateNewUserOrFirstLogin() {
    isAddressUpdateRequired(this.localStorageService, this.modalContentService, this.activeModal, this.router);
    const newUser = this.localStorageService.get(LOCALSTORAGE_KEYS.NEW_ACCOUNT);
    const firstLogin = this.localStorageService.get(LOCALSTORAGE_KEYS.FIRST_LOGIN);
    if (newUser || firstLogin) {
      this.openModalWelcomeMessage();
      this.localStorageService.remove(LOCALSTORAGE_KEYS.NEW_ACCOUNT);
      this.localStorageService.remove(LOCALSTORAGE_KEYS.FIRST_LOGIN);
      // Register klaviyo event:
      this.registerKlaviyoNewSignupEvent();
    }
  }

  private registerKlaviyoNewSignupEvent() {
    const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION)
    if (!session) return;
    if (session && session.deliveryInfo?.deliveryDay) {
      // Sign Up Date Config:
      const currentDate = new Date();
      const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const currentDay = (currentDate.getDate()).toString().padStart(2, '0');
      const signupDate = `${currentDate.getFullYear()}-${currentMonth}-${currentDay}`;
      const formattedSignupDate = formatDateToReadableString(signupDate).mmddyyyyFormat.replace(/\//g, "-");
      // ***********************
      this.klaviyoService.trackEvent(KLAVIYOEVENTS.NewSignUp, {
        'Sign Up Date': formattedSignupDate,
        'Delivery Date': session.deliveryInfo?.deliveryDay,
        'Cut-Off Day and Time': `${session.deliveryInfo?.cutoffDay} ${session.deliveryInfo?.cutoffTime}`
      })
    };
  }

  private setUpSelectedCategory(url: string) {
    if (this.signalsStoreService.filterByProducer()) return;
    if (url.indexOf('/shop') > -1) {
      const parts = url.split('/');
      let categoryName = parts[parts.length - 1].toLowerCase();
      // Validation for query params:
      if (categoryName.indexOf('?') > -1) categoryName = categoryName.split('?')[0];
      if (!this.menuItems().length) return;
      let category = undefined;
      if (categoryName === 'shop') {
        if (
          this.signalsStoreService.isFiltering() &&
          this.signalsStoreService.selectedCategory().id > -1
        ) {
          category = this.signalsStoreService.selectedCategory();
        } else {
          category = this.menuItems()[0];
        }
      } else
        category = this.menuItems().find(
          (e: any) => formatStringForURL(e.name) === categoryName
        );
      if (!category) return;
      this.triggerCategoryInfoFetch(category, categoryName === 'shop');
      // Get products info only if the user is not filtering.
      if (!this.signalsStoreService.isFiltering())
        this.productService.getProductsResume(category.id);
    } else {
      this.signalsStoreService.selectedCategory.set({ id: -1, name: '' });
    }
  }

  private getSubscriptions() {
    if (!isPlatformBrowser(this.platformId)) return;
    if (!this.hasSession()) return;
    this.subscriptionsService.get();
  }

  private setUpSettingsPermissions() {
    const permissions = this.signalsStoreService.permissions();
    if (!permissions) return;

    // Set Up Settings Pages:
    const items = JSON.parse(JSON.stringify(SETTINGS_PAGES))
    const settingPages = items.map((p: { children: any[]; }) => {
      const children = p.children.filter((c: { permissionName: string; }) => {
        const keys = c.permissionName.split('.');
        let permission: any = permissions;
        for (const key of keys) {
          permission = permission[key]
        }
        return permission.allowed
      });
      p.children = children || [];
      return p
    }).filter((p: { children: string | any[]; }) => p.children?.length);

    return settingPages;
  }

  private setUpRightElementPermissions() {
    // Get the local storage permissions variable:
    const permissions: any = this.signalsStoreService.permissions();
    if (!permissions) return;

    // Set Up Right Elements:
    const items = JSON.parse(JSON.stringify(RIGHT_ELEMENTS));
    const rightElements = items.filter((p: { showWithoutPermission: any; permissionName: string; }) => {
      if (p.showWithoutPermission) return true;
      const keys = p.permissionName.split('.');
      let permission = permissions;
      for (const key of keys) {
        permission = permission[key]
      }
      return permission.allowed
    });

    return rightElements || []
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const targetElement = event.target as HTMLElement;
    if (this.matSelect?.panelOpen && !this.matSelect?._elementRef.nativeElement.contains(targetElement)) {
      this.matSelect.close();
    }
  }

  goToSection(item: SidebarCheckList) {

    const element = document.getElementById(item.key as string);

    if (element)
      element?.scrollIntoView(true);
    else if (item.sublist && item.sublist.length)
      document.getElementById(item.sublist[0].key as string)?.scrollIntoView(true);
  }

  findYourDeliveryDay() {

    const data: ModalContentData = {
      title: 'Find Your Delivery Day',
      textContent: 'We need to know your delivery address so we can see what products are available in your area.'
    };

    this.modalContentService
      .openModal(ModalContentTypes.CHECK_ADDRESS, data);
  }

  private validateIfShouldUpdateOrder() {

    if (!this.localStorageService.get(LOCALSTORAGE_KEYS.SIGNUP_SHOULD_UPDATE_ORDER)) return;

    this.paytmentMethodSignupService
      .updateOrder(false)
      ?.subscribe(() => this.localStorageService.remove(LOCALSTORAGE_KEYS.SIGNUP_SHOULD_UPDATE_ORDER));
  }

  #getUserPreferences(userId: number) {
    this.firebaseService
      .getById(FIREBASE_COLLECTIONS.PREFERENCES, userId.toString(), ((result: UserPreferences) => {
        if (!result) {
          return this.signalsStoreService.userPreferences.set({
            dontShowAgainPrevDislike: {
              date: 0,
              value: false
            },
            holdUpSubscriptions: {
              date: 0,
              value: false
            }
          });
        }

        this.signalsStoreService.userPreferences.set(result);
        this.#validateExpiredUserPreferences(userId, result);
      }));
  }

  #validateExpiredUserPreferences(userId: number, preferences: { [key: string]: { value: boolean, date: number } }) {

    let update = false;

    for (const key in preferences) {

      const { value, date } = preferences[key];

      if (!value)
        continue;

      const diffMonths = DateTime.utc().diff(DateTime.fromMillis(date), 'months').months;

      if (diffMonths >= 3) {
        preferences[key].value = false;
        preferences[key].date = 0;
        update = true;
      }
    }

    if (!update)
      return;

    this.firebaseService.update(FIREBASE_COLLECTIONS.PREFERENCES, userId, preferences);
  }

  navigateTo(item: RightElement) {

    if (item.shouldShowCartPreview) {
      this.signalsStoreService.showCartPreview.update(value => this.signalsStoreService.isInOrderComponent() ? false : !value);
      return;
    }

    this.router.navigate([item.route]);
  }

  #openModalDislikeFav() {

    const sessionData = this.signalsStoreService.sessionSignal();
    if (!sessionData || !sessionData?.settings?.firstLogin) return

    this.#getProductsDislikeLike();
  }

  #getProductsDislikeLike() {

    this.productService
      .getFavoritesDislikes(false)
      .subscribe(([favorites, dislikes]) => {
        // Because when recharging it takes it from the storage
        this.#updateFirstLoginLocalStorage();
        if (!dislikes.data.length && !favorites.data.length) return;
        this.#openModalInitialPreferences(dislikes.data, favorites.data);
      });
  }

  #updateFirstLoginLocalStorage() {
    const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (!session) return;

    session.settings.firstLogin = false;
    this.localStorageService.set(LOCALSTORAGE_KEYS.SESSION, session);
    this.signalsStoreService.sessionSignal.set(session);
  }

  #openModalInitialPreferences(dislikes: Product[], likes: Product[]) {

    this.modalContentService
      .openModal(ModalContentTypes.DISLIKE_FAV, {
        closeable: true,
        title: 'Your Preferences',
        preferences: {
          dislikes,
          likes
        }
      }, {
        size: 'xl',
        keyboard: false,
        backdrop: 'static'
      })
      .closed
      .subscribe();
  }

  private setUpSEOTags(categoryName: string, formattedCategoryName: string) {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = `shop/${formattedCategoryName}`;
    const title = `${categoryName} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }

}
